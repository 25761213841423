<template>
  <div>
    <div class="cepContact A4Container flexCloumnCenter pageBreak_no">
      <div class="form" style='height: 100%;'>

        <el-row class='flexCenter' style='height: 50%;'>
          <el-col :span='12' class='flexCloumn' style='height: 100%;'>
            <el-row class='flexStart cell FS18 bold' style='border-left: 0;padding: 0 3px;'>五、一周实习小节： </el-row>
            <el-row class='cell' style='width: 100%;height: calc( 100% - 30px );border-left: 0;'>
              <div class='content'
                style='width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;'>
                <div :id="'divCntnrD_' + idx" style='width:100%;height: 100%;overflow: hidden;'>
                  <div :id="'resizeDivD_' + idx" style='width: 100%;word-break: break-all'>
                    {{ dataInfo.workContent }}
                  </div>
                </div>
              </div>
            </el-row>
          </el-col>
          <el-col :span='12' class='flexCloumn' style='height: 100%;'>
            <el-row class='flexStart cell FS18 bold' style='padding: 0 3px;'>存在问题及建议：</el-row>
            <el-row class='cell' style='width: 100%;height: calc( 100% - 30px );'>
              <div class='content'
                style='width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;'>
                <div :id="'divCntnrB_' + idx" style='width:100%;height: 100%;overflow: hidden;'>
                  <div :id="'resizeDivB_' + idx" style='width: 100%;word-break: break-all'>
                    {{ dataInfo.problem }}
                  </div>
                </div>
              </div>
            </el-row>
          </el-col>
        </el-row>

        <!--        六、带教老师评语-->
        <el-row class='flexCloumn cell' style='border-left: 0;position: relative;height: calc( 50% - 116px );'>
          <div class='content' style='height: calc( 100% - 50px );'>
            六、带教老师评语：
          </div>
          <div class='flexStart' style='padding-left: 10px;position: absolute;right:0;bottom:0;'>
            <div>签名：</div>
            <img v-if='dataInfo.aaa' :src='dataInfo.aaa' alt='' style='width: 100px;max-height: 50px;margin-top: 2px;'>
            <div v-else style='width: 100px;height: 50px;'></div>
          </div>
        </el-row>

        <div class='cell' style='width: 100%;height: 116px;position: relative;border-left: 0;'>
          <div class='content'>
            {{ dataInfo.bzrComment }}
          </div>
          <div class='flexStart' style='padding-left: 10px;position: absolute;right:0;bottom:0;'>
            <div>班主任签名：</div>
            <img v-if='dataInfo.bzrSignUrl' :src='dataInfo.bzrSignUrl' alt=''
              style='width: 100px;max-height: 50px;margin-top: 2px;'>
            <div v-else style='width: 100px;height: 50px;'></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Report2',
  data() {
    return {
      dataover: false,
      userid: '',
      info: {
        ksInfo: {}, evaluation: {}, identification: {},
      },
      list: [
        { name: '医疗文件书写', value: 10 }, { name: '得分规范操作能力', value: 10 },
        { name: '诊断(护理）能力', value: 10 }, { name: '无菌技术操作', value: 10 },
        { name: '观察病情能力', value: 10 }, { name: '理论联系实际', value: 10 },
        { name: '处理问题能力', value: 10 }, { name: '完成任务情况', value: 10 },
        { name: '病房管理能力', value: 10 }, { name: '独立工作能力', value: 10 }
      ]
    }
  },
  props: {
    idx: {
      type: Number,
      default: 1
    },
    dataInfo: {
      type: Object,
      default: {}
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let _this = this
      setTimeout(function () {
        // console.log(9797,_this.dataInfo)

        _this.$nextTick(() => {
          _this.resizeA()
        })
      }, 800)

    },
    stripscript(s) {
      var pattern = new RegExp('\n')
      var rs = ''
      for (var i = 0; i < s.length; i++) {
        rs = rs + s.substr(i, 1).replace(pattern, '')
      }
      return rs
    },
    resizeA() {
      let divHeight = document.getElementById(`divCntnrD_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivD_${this.idx}`).offsetHeight
      // console.log('a', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 5) {
        document.getElementById(`resizeDivD_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivD_${this.idx}`).offsetHeight
        i++
      }
    },
  }
}
</script>
<style lang="less" type="text/less">
.cepContact .el-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.cepContact .el-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
@font-face {
  font-family: "华文中宋";
  src: url("/public/font/hwzs.ttf");
}

.fontFamily_HWZS {
  font-family: '华文中宋';
}

.flexStretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.cepContact {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .ydyf {
    .nth:nth-of-type(2n+1) {
      .bl0 {
        border-left: 0;
      }
    }
  }

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    border: 1px solid #000;
    border-top: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .el-row {
      //border-top: 1px solid #000;
      //border-bottom: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


.cepContact {

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .FS18 {
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-family: '华文中宋';

    word-break: break-all;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: '华文中宋';

  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
    font-family: '华文中宋';

  }
}

.uline {
  text-decoration: underline;
}
</style>


