<template>
    <div class="coverPage A4Container flexCenter pageBreak_no">
        <div class="flexCloumnBetween" style="width: 80%;height: 80%;">

            <div>
                <div class="subTitle fontFamily_HWZS"> {{ stuInfo.xuerxiao || '-' }}</div>
                <div class='title fontFamily_HWZS' style='font-weight: 500;font-size: 36px;'>
                    周&nbsp;记&nbsp;本</div>
            </div>
            <div class="form">

                <div class="formItem flexStart">
                    <div class="label fontFamily_HWZS">专业</div>
                    <div class="input">{{ stuInfo.professionalName || '-' }}</div>
                </div>
                <div class="formItem flexStart">
                    <div class="label fontFamily_HWZS">班级</div>
                    <div class="input">{{ stuInfo.class_name || '-' }}</div>
                </div>
                <div class="formItem flexStart">
                    <div class="label fontFamily_HWZS">学号</div>
                    <div class="input fontFamily_HWZS">{{ stuInfo.stu_code || '-' }}</div>
                </div>
                <div class="formItem flexStart">
                    <div class="label fontFamily_HWZS">学生姓名</div>
                    <div class="input fontFamily_HWZS">{{ stuInfo.stuName || '-' }}</div>
                </div>
                <div class="formItem flexStart">
                    <div class="label fontFamily_HWZS">实习单位</div>
                    <div class="input fontFamily_HWZS">{{ stuInfo.firm_name || '-' }}</div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'cover',
    props: {
        stuInfo: {
            type: Object,
            default: {}
        },
    }
}
</script>
